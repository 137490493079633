.container.testimonials__container {
    width: 40%;
}

.club__avatar {
    width: 4rem;
    aspect-ratio: 1/1;
    overflow: hidden;
    border-radius: 50%;
    margin: 0 auto 1rem;
    border: 0.4rem solid var (--color-primary-varient);
}

.club__name {
    text-align: center;
}

.testimonial {
    background: var(--color-primary-varient);
    padding: 2rem;
    border-radius: 2rem;
    user-select: none;
}

.club__review {
    color: var(--color-light);
    font-weight: 300;
    display: block;
    width: 80%;
    margin: 0.8rem auto 0;
}

span {
    font-weight: 400;
    color: white;
}

.testimonials__category {
    margin-bottom: 1rem;
}



/* ======= MEDIA QUERIES (Medium Devices) ====== */
@media screen and (max-width: 1024px) {
    .container.testimonials__container {
        width: 60%;
    }
}

/* ======= MEDIA QUERIES (Small Devices) ====== */
@media screen and (max-width: 600px) {
    .container.testimonials__container {
        width: var(--container-width-sm);
    }
    .club__review {
        width: var(--container-width-sm);
    }
}