header {
  height: fit-content;
  padding-top: 5rem;
  overflow: hidden;
  background-image: url("../../assets/image.png");
  background-size: cover;
  display: flex;
  flex-direction: column;
}

.header__container {
  text-align: center;
  height: 100%;
  position: relative;
}

.grid__container {
  display: grid;
  grid-template-columns: 1.2fr 1fr;
  justify-content: space-evenly;
  justify-items: center;
  align-content: space-evenly;
  align-items: center;
}

/*======================= CTA =====================*/
.cta {
  margin-top: 2.5rem;
  display: flex;
  gap: 1.2rem;
  justify-content: center;
}

/*======================= HEADER SOCIALS =====================*/
.header__socials {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.8rem;
  position: absolute;
  left: 0;
  bottom: 3rem;
}

.header__socials::after {
  content: "";
  width: 1px;
  height: 3rem;
  background-color: var(--color-primary);
}

/*======================= Introduction=====================*/
p::after {
  content: "|";
  display: inline-block;
  animation-name: typing;
  animation-duration: 0.4s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@keyframes typing {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.me {
  background: transparent;
  animation-name: up-down;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@keyframes up-down {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-10px);
  }
}

/*======================= Scroll Down =====================*/
.scroll__down {
  position: absolute;
  right: -2.3rem;
  bottom: 5rem;
  transform: rotate(90deg);
  font-weight: 300;
  font-size: 0.9rem;
}

/**************************** MEDIA QUERIES (MEDIUM DEVICES) ***************************/
@media screen and (max-width: 1024px) {
  .grid__container {
    grid-template-columns: 1fr;
    gap: 2rem;
  }
}

/**************************** MEDIA QUERIES (SMALL DEVICES) ***************************/
@media screen and (max-width: 600px) {
  header {
    height: 100vh;
  }
  .header__socials,
  .scroll__down {
    display: none;
  }
  .grid__container {
    grid-template-columns: 1fr;
    padding-left: 5%;
    padding-right: 5%;
    gap: 2rem;
  }
}
