.portfolio__container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2.5rem;
}

.portfolio__item {
    background: var(--color-bg-varient);
    padding: 1.2rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
}

.portfolio__item:hover {
    border-color: var(--color-primary-varient);
    background: transparent;
}

.portfolio__item-image {
    border-radius: 1.5rem;
    overflow: hidden;
    width: 100%;
    height: 200px;
    display: inline-block;
    position: relative;
}

.portfolio__item-image img {
    display: block;
    width: 100%;
    height: auto;
    filter: grayscale(100%);
    transition: all 0.5s ease-in-out;
  }

.portfolio__item-image:hover img {
    filter: grayscale(0%);
}

.portfolio__item h3 {
    margin: 1.2rem 0 2rem;
}

.portfolio__item-cta {
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
}

/**************************** MEDIA QUERIES (MEDIUM DEVICES) ***************************/
@media screen and (max-width: 1200px) {
    .portfolio__container {
        grid-template-columns: 1fr 1fr;
        gap: 1rem;
    }
}

/**************************** MEDIA QUERIES (SMALL DEVICES) ***************************/
@media screen and (max-width: 600px) {
    .portfolio__container {
        grid-template-columns: 1fr;
        gap: 1rem;
    }
}